import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DeviceProps from '@magnus/react-native-device-props';
import { initializeApp as initFirebase } from '@firebase/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Localization } from 'core/modules/i18n';

import configureStore from './store';
import localization from './localization';
import packageInfo from '../package.json';

import 'src/styles/global.scss';
import 'src/styles/index.scss';


const App = (AppRouter, projectType) => {
  const { store, persistor } = configureStore(projectType);
  Localization.init(localization);
  DeviceProps.setAppVersion(packageInfo.version);

  if (projectType) {
    const initApp = async () => {
      const { load, setProjectType } = await import('./store/app/actions');
      store.dispatch(setProjectType(projectType));
      store.dispatch(load());
    };

    initApp();
  }

  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
          <ToastContainer position="bottom-center" autoClose={5000} closeOnClick />
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

initFirebase({
  apiKey: 'AIzaSyCg_-zDakcWJgL0HRYuFoxrxPJIYB0AjaQ',
  authDomain: 'reflectio-59809.firebaseapp.com',
  projectId: 'reflectio-59809',
  storageBucket: 'reflectio-59809.appspot.com',
  messagingSenderId: '622365671179',
  appId: '1:622365671179:web:db7668fb60b0f0badaec0e',
  measurementId: 'G-CE3HNDPP9L',
});

export const render = (AppRouter, projectType) => {
  ReactDOM.render(App(AppRouter, projectType), document.getElementById('root'));
};
