import React from 'react';

import { PROJECTS_TYPES } from 'core/constants/general';

import { render } from './render';

let pType = window.location.pathname.split('/')[1];

let promiseApp;

switch (pType) {
  case PROJECTS_TYPES.FLOW:
  case PROJECTS_TYPES.FLOW_PP:
    promiseApp = import('./screens/onboarding');
    break;
  case PROJECTS_TYPES.QUIZ:
  case PROJECTS_TYPES.QUIZ_PP:
  case PROJECTS_TYPES.TQUIZ:
  case PROJECTS_TYPES.TQUIZ_PP:
    promiseApp = import('./screens/quiz');
    break;
  case 'terminate':
    promiseApp = import('./screens/terminate');
    pType = undefined;
    break;
  default:
    promiseApp = Promise.resolve({ default: () => <span>Enter project type in url</span> });
    pType = undefined;
    break;
}

promiseApp.then(({ default: App }) => {
  render(App, pType);
});
