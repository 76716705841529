export const SUBSCRIPTION_NAMES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
};

export const TERMINATE_NAMES = {
  MAIN: 'TERMINATE_MAIN',
  CHECK: 'TERMINATE_CHECK',
  SORRY: 'TERMINATE_SORRY',
  ERROR: 'TERMINATE_ERROR',
};

export const ONBOARDING_NAMES = {
  GENDER: 'GENDER',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  IMPROVEMENTS: 'IMPROVEMENTS',
  LIFESTYLE_CHANGES: 'LIFESTYLE_CHANGES',
  DAILY_ROUTINES: 'DAILY_ROUTINES',
  MINDFULNESS: 'MINDFULNESS',
  TRANSFORMATION: 'TRANSFORMATION',
  PROGRAM: 'PROGRAM',
  START_HABIT: 'START_HABIT',
  EMAIL: 'EMAIL',
  CREATE_PLAN_LOADING: 'CREATE_PLAN_LOADING',
};

export const QUIZ_NAMES = {
  QUIZ_WELCOME: 'QUIZ_WELCOME',
  QUIZ_WELCOME_MENTAL: 'QUIZ_WELCOME_MENTAL',
  LIVING_JOYFUL: 'LIVING_JOYFUL',
  HAPPY: 'HAPPY',
  SELECTED_STATEMENTS: 'SELECTED_STATEMENTS',
  QUIZ_TRANSITION1: 'QUIZ_TRANSITION1',
  RECENT_TIMES: 'RECENT_TIMES',
  MOST_JOY: 'MOST_JOY',
  FEEL_DOWN: 'FEEL_DOWN',
  YOUR_MOOD: 'YOUR_MOOD',
  TOUGH_TIMES: 'TOUGH_TIMES',
  QUIZ_TRANSITION2: 'QUIZ_TRANSITION2',
  FEEL_ANGRY: 'FEEL_ANGRY',
  USUALLY_DEAL: 'USUALLY_DEAL',
  USUALLY_RESPOND: 'USUALLY_RESPOND',
  MENTAL_SUPERPOWER: 'MENTAL_SUPERPOWER',
  QUIZ_TRANSITION3: 'QUIZ_TRANSITION3',
  TOOLS: 'TOOLS',
  PICK_TOOLS: 'PICK_TOOLS',
  KIND_CONTENT: 'KIND_CONTENT',
  YOU_JOURNAL: 'YOU_JOURNAL',
  JOURNALING_HELPS: 'JOURNALING_HELPS',
  QUIZ_TRANSITION4: 'QUIZ_TRANSITION4',
  QUIZ_TRANSITION6: 'QUIZ_TRANSITION6',
  INVEST: 'INVEST',
  IMPROVE_SHORTLY: 'IMPROVE_SHORTLY',
  STOPS: 'STOPS',
  BELIEVE: 'BELIEVE',
  QUIZ_TRANSITION5: 'QUIZ_TRANSITION5',
  QUIZ_NAME: 'QUIZ_NAME',
  GENDER: 'GENDER',
  GENDER_NEW: 'GENDER_NEW',
  AGE: 'AGE',
  MORE_LITERACY: 'MORE_LITERACY',
  QUIZ_CRATE_PLAN: 'QUIZ_CREATE_PLAN',
  QUIZ_EMAIL: 'QUIZ_EMAIL',
  ONBOARDING_CREATE_PLAN: 'ONBOARDING_CREATE_PLAN',
  ONBOARDING_EMAIL: 'ONBOARDING_EMAIL',
  START: 'START',
  DESCRIPTION: 'DESCRIPTION',
  ONBOARDING_GENDER: 'ONBOARDING_GENDER',
  GOALS: 'GOALS',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  FOCUS_TIME: 'FOCUS_TIME',
  FOCUS_YOU: 'FOCUS_YOU',
  SLEEP: 'SLEEP',
  TQUIZ_TARGETS: 'TQUIZ_TARGETS',
  TQUIZ_BURNOUT_LEVEL_FIRST: 'TQUIZ_BURNOUT_LEVEL_FIRST',
  TQUIZ_BURNOUT_LEVEL_SECOND: 'TQUIZ_BURNOUT_LEVEL_SECOND',
  TQUIZ_BURNOUT_LEVEL_THIRD: 'TQUIZ_BURNOUT_LEVEL_THIRD',
  TQUIZ_BURNOUT_LEVEL_FOURTH: 'TQUIZ_BURNOUT_LEVEL_FOURTH',
  TQUIZ_BURNOUT_LEVEL_FIFTH: 'TQUIZ_BURNOUT_LEVEL_FIFTH',
  TQUIZ_TRANSITION_BURNOUT_LEVEL: 'TQUIZ_TRANSITION_BURNOUT_LEVEL',
  TQUIZ_STRESS_LEVEL_FIRST: 'TQUIZ_STRESS_LEVEL_FIRST',
  TQUIZ_STRESS_LEVEL_SECOND: 'TQUIZ_STRESS_LEVEL_SECOND',
  TQUIZ_STRESS_LEVEL_THIRD: 'TQUIZ_STRESS_LEVEL_THIRD',
  TQUIZ_STRESS_LEVEL_FOURTH: 'TQUIZ_STRESS_LEVEL_FOURTH',
  TQUIZ_STRESS_LEVEL_FIFTH: 'TQUIZ_STRESS_LEVEL_FIFTH',
  TQUIZ_TRANSITION_STRESS_LEVEL: 'TQUIZ_TRANSITION_STRESS_LEVEL',
  TQUIZ_CHILDHOOD_TRAUMA_FIRST: 'TQUIZ_CHILDHOOD_TRAUMA_FIRST',
  TQUIZ_CHILDHOOD_TRAUMA_SECOND: 'TQUIZ_CHILDHOOD_TRAUMA_SECOND',
  TQUIZ_CHILDHOOD_TRAUMA_THIRD: 'TQUIZ_CHILDHOOD_TRAUMA_THIRD',
  TQUIZ_CHILDHOOD_TRAUMA_FOURTH: 'TQUIZ_CHILDHOOD_TRAUMA_FOURTH',
  TQUIZ_CHILDHOOD_TRAUMA_FIFTH: 'TQUIZ_CHILDHOOD_TRAUMA_FIFTH',
  TQUIZ_TRANSITION_CHILDHOOD_TRAUMA: 'TQUIZ_TRANSITION_CHILDHOOD_TRAUMA',
  TQUIZ_POSITIVE_THINKING_FIRST: 'TQUIZ_POSITIVE_THINKING_FIRST',
  TQUIZ_POSITIVE_THINKING_SECOND: 'TQUIZ_POSITIVE_THINKING_SECOND',
  TQUIZ_POSITIVE_THINKING_THIRD: 'TQUIZ_POSITIVE_THINKING_THIRD',
  TQUIZ_POSITIVE_THINKING_FOURTH: 'TQUIZ_POSITIVE_THINKING_FOURTH',
  TQUIZ_POSITIVE_THINKING_FIFTH: 'TQUIZ_POSITIVE_THINKING_FIFTH',
  TQUIZ_TRANSITION_POSITIVE_THINKING: 'TQUIZ_TRANSITION_POSITIVE_THINKING',
  TQUIZ_CONFIDENCE_LEVEL_FIRST: 'TQUIZ_CONFIDENCE_LEVEL_FIRST',
  TQUIZ_CONFIDENCE_LEVEL_SECOND: 'TQUIZ_CONFIDENCE_LEVEL_SECOND',
  TQUIZ_CONFIDENCE_LEVEL_THIRD: 'TQUIZ_CONFIDENCE_LEVEL_THIRD',
  TQUIZ_CONFIDENCE_LEVEL_FOURTH: 'TQUIZ_CONFIDENCE_LEVEL_FOURTH',
  TQUIZ_CONFIDENCE_LEVEL_FIFTH: 'TQUIZ_CONFIDENCE_LEVEL_FIFTH',
  TQUIZ_TRANSITION_CONFIDENCE_LEVEL: 'TQUIZ_TRANSITION_CONFIDENCE_LEVEL',
  TQUIZ_DEAL_WITH_EMOTIONS_FIRST: 'TQUIZ_DEAL_WITH_EMOTIONS_FIRST',
  TQUIZ_DEAL_WITH_EMOTIONS_SECOND: 'TQUIZ_DEAL_WITH_EMOTIONS_SECOND',
  TQUIZ_DEAL_WITH_EMOTIONS_THIRD: 'TQUIZ_DEAL_WITH_EMOTIONS_THIRD',
  TQUIZ_DEAL_WITH_EMOTIONS_FOURTH: 'TQUIZ_DEAL_WITH_EMOTIONS_FOURTH',
  TQUIZ_DEAL_WITH_EMOTIONS_FIFTH: 'TQUIZ_DEAL_WITH_EMOTIONS_FIFTH',
  TQUIZ_TRANSITION_DEAL_WITH_EMOTIONS: 'TQUIZ_TRANSITION_DEAL_WITH_EMOTIONS',
  TQUIZ_TOXIC_RELATIONSHIPS_FIRST: 'TQUIZ_TOXIC_RELATIONSHIPS_FIRST',
  TQUIZ_TOXIC_RELATIONSHIPS_SECOND: 'TQUIZ_TOXIC_RELATIONSHIPS_SECOND',
  TQUIZ_TOXIC_RELATIONSHIPS_THIRD: 'TQUIZ_TOXIC_RELATIONSHIPS_THIRD',
  TQUIZ_TOXIC_RELATIONSHIPS_FOURTH: 'TQUIZ_TOXIC_RELATIONSHIPS_FOURTH',
  TQUIZ_TOXIC_RELATIONSHIPS_FIFTH: 'TQUIZ_TOXIC_RELATIONSHIPS_FIFTH',
  TQUIZ_TRANSITION_TOXIC_RELATIONSHIPS: 'TQUIZ_TRANSITION_TOXIC_RELATIONSHIPS',
  TQUIZ_ANXIETY_LEVEL_FIRST: 'TQUIZ_ANXIETY_LEVEL_FIRST',
  TQUIZ_ANXIETY_LEVEL_SECOND: 'TQUIZ_ANXIETY_LEVEL_SECOND',
  TQUIZ_ANXIETY_LEVEL_THIRD: 'TQUIZ_ANXIETY_LEVEL_THIRD',
  TQUIZ_ANXIETY_LEVEL_FOURTH: 'TQUIZ_ANXIETY_LEVEL_FOURTH',
  TQUIZ_ANXIETY_LEVEL_FIFTH: 'TQUIZ_ANXIETY_LEVEL_FIFTH',
  TQUIZ_TRANSITION_ANXIETY_LEVEL: 'TQUIZ_TRANSITION_ANXIETY_LEVEL',
};

export const TARGETS = {
  BURNOUT_LEVEL: 'BURNOUT_LEVEL',
  STRESS_LEVEL: 'STRESS_LEVEL',
  CHILDHOOD_TRAUMA: 'CHILDHOOD_TRAUMA',
  POSITIVE_THINKING: 'POSITIVE_THINKING',
  CONFIDENCE_LEVEL: 'CONFIDENCE_LEVEL',
  DEAL_WITH_EMOTIONS: 'DEAL_WITH_EMOTIONS',
  TOXIC_RELATIONSHIPS: 'TOXIC_RELATIONSHIPS',
  ANXIETY_LEVEL: 'ANXIETY_LEVEL',
};

export const TARGETS_PRIORITY = [
  TARGETS.STRESS_LEVEL,
  TARGETS.DEAL_WITH_EMOTIONS,
  TARGETS.POSITIVE_THINKING,
  TARGETS.ANXIETY_LEVEL,
];

export const TARGETS_NAMES = {
  [TARGETS.BURNOUT_LEVEL]: [
    QUIZ_NAMES.TQUIZ_BURNOUT_LEVEL_FIRST,
    QUIZ_NAMES.TQUIZ_BURNOUT_LEVEL_SECOND,
    QUIZ_NAMES.TQUIZ_BURNOUT_LEVEL_THIRD,
    QUIZ_NAMES.TQUIZ_BURNOUT_LEVEL_FOURTH,
    QUIZ_NAMES.TQUIZ_BURNOUT_LEVEL_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_BURNOUT_LEVEL,
  ],
  [TARGETS.STRESS_LEVEL]: [
    QUIZ_NAMES.TQUIZ_STRESS_LEVEL_FIRST,
    QUIZ_NAMES.TQUIZ_STRESS_LEVEL_SECOND,
    QUIZ_NAMES.TQUIZ_STRESS_LEVEL_THIRD,
    QUIZ_NAMES.TQUIZ_STRESS_LEVEL_FOURTH,
    QUIZ_NAMES.TQUIZ_STRESS_LEVEL_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_STRESS_LEVEL,
  ],
  [TARGETS.CHILDHOOD_TRAUMA]: [
    QUIZ_NAMES.TQUIZ_CHILDHOOD_TRAUMA_FIRST,
    QUIZ_NAMES.TQUIZ_CHILDHOOD_TRAUMA_SECOND,
    QUIZ_NAMES.TQUIZ_CHILDHOOD_TRAUMA_THIRD,
    QUIZ_NAMES.TQUIZ_CHILDHOOD_TRAUMA_FOURTH,
    QUIZ_NAMES.TQUIZ_CHILDHOOD_TRAUMA_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_CHILDHOOD_TRAUMA,
  ],
  [TARGETS.POSITIVE_THINKING]: [
    QUIZ_NAMES.TQUIZ_POSITIVE_THINKING_FIRST,
    QUIZ_NAMES.TQUIZ_POSITIVE_THINKING_SECOND,
    QUIZ_NAMES.TQUIZ_POSITIVE_THINKING_THIRD,
    QUIZ_NAMES.TQUIZ_POSITIVE_THINKING_FOURTH,
    QUIZ_NAMES.TQUIZ_POSITIVE_THINKING_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_POSITIVE_THINKING,
  ],
  [TARGETS.CONFIDENCE_LEVEL]: [
    QUIZ_NAMES.TQUIZ_CONFIDENCE_LEVEL_FIRST,
    QUIZ_NAMES.TQUIZ_CONFIDENCE_LEVEL_SECOND,
    QUIZ_NAMES.TQUIZ_CONFIDENCE_LEVEL_THIRD,
    QUIZ_NAMES.TQUIZ_CONFIDENCE_LEVEL_FOURTH,
    QUIZ_NAMES.TQUIZ_CONFIDENCE_LEVEL_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_CONFIDENCE_LEVEL,
  ],
  [TARGETS.DEAL_WITH_EMOTIONS]: [
    QUIZ_NAMES.TQUIZ_DEAL_WITH_EMOTIONS_FIRST,
    QUIZ_NAMES.TQUIZ_DEAL_WITH_EMOTIONS_SECOND,
    QUIZ_NAMES.TQUIZ_DEAL_WITH_EMOTIONS_THIRD,
    QUIZ_NAMES.TQUIZ_DEAL_WITH_EMOTIONS_FOURTH,
    QUIZ_NAMES.TQUIZ_DEAL_WITH_EMOTIONS_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_DEAL_WITH_EMOTIONS,
  ],
  [TARGETS.TOXIC_RELATIONSHIPS]: [
    QUIZ_NAMES.TQUIZ_TOXIC_RELATIONSHIPS_FIRST,
    QUIZ_NAMES.TQUIZ_TOXIC_RELATIONSHIPS_SECOND,
    QUIZ_NAMES.TQUIZ_TOXIC_RELATIONSHIPS_THIRD,
    QUIZ_NAMES.TQUIZ_TOXIC_RELATIONSHIPS_FOURTH,
    QUIZ_NAMES.TQUIZ_TOXIC_RELATIONSHIPS_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_TOXIC_RELATIONSHIPS,
  ],
  [TARGETS.ANXIETY_LEVEL]: [
    QUIZ_NAMES.TQUIZ_ANXIETY_LEVEL_FIRST,
    QUIZ_NAMES.TQUIZ_ANXIETY_LEVEL_SECOND,
    QUIZ_NAMES.TQUIZ_ANXIETY_LEVEL_THIRD,
    QUIZ_NAMES.TQUIZ_ANXIETY_LEVEL_FOURTH,
    QUIZ_NAMES.TQUIZ_ANXIETY_LEVEL_FIFTH,
    QUIZ_NAMES.TQUIZ_TRANSITION_ANXIETY_LEVEL,
  ],
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  QUIZ_NAMES.QUIZ_WELCOME,
  QUIZ_NAMES.QUIZ_WELCOME_MENTAL,
  QUIZ_NAMES.ONBOARDING_CREATE_PLAN,
  QUIZ_NAMES.QUIZ_CRATE_PLAN,
  QUIZ_NAMES.ONBOARDING_GENDER,
  QUIZ_NAMES.ONBOARDING_EMAIL,
  QUIZ_NAMES.GENDER,
  QUIZ_NAMES.GENDER_NEW,
  QUIZ_NAMES.START,
  QUIZ_NAMES.DESCRIPTION,
  SUBSCRIPTION_NAMES.MAIN,
  SUBSCRIPTION_NAMES.SUCCESS,
];
