import { SET_ROUTES, SET_START_ROUTE, SET_STEP, SET_COMPLETED, SET_TARGETS } from './types';
import { TARGETS_PRIORITY } from 'src/constants/screens';

export const initialState = {
  routes: null,
  isCompleted: false,
  currentStep: 0,
  startRoute: null,
  targets: TARGETS_PRIORITY,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };

    case SET_START_ROUTE:
      return {
        ...state,
        startRoute: action.payload,
      };

    case SET_COMPLETED:
      return {
        ...state,
        isCompleted: true,
      };

    case SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    case SET_TARGETS:
      return {
        ...state,
        targets: action.payload,
      };

    default:
      return state;
  }
};

reducer.whitelist = ['isCompleted'];

export default reducer;
